<template>
  <Menu
    ref="containerElem"
    as="div"
    class="relative inline-block text-left"
    @mouseenter="onMouseHover"
    @mouseleave="onMouseHover"
  >
    <div
      ref="root"
      :class="buttonContainerClass"
      @click="$emit('toggle')"
    >
      <MenuButton
        :class="buttonClass"
        :disabled="disabled"
      >
        <slot>
          <span :class="labelClass">
            {{ truncateLength ? $h.truncate(label, truncateLength ) : label }}
          </span>

          <slot name="icon">
            <ChevronDownIcon
              class="chev-icon chev-small inline-block"
              :class="iconClass"
              aria-hidden="true"
            />
          </slot>
        </slot>
      </MenuButton>
    </div>

    <span v-show="isForcedOpen || !isStatic">
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          v-if="options.length > 0 || $slots['content'] || $slots['footer']"
          class="absolute py-2.5 z-30 w-max rounded-md shadow-floating bg-white focus:outline-none"
          :class="[menuClass, fixed ? '!fixed' : '']"
          :static="isStatic"
          :style="position"
        >
          <div
            v-if="(isStatic && isForcedOpen) || !isStatic "
            ref="menu"
          >
            <div v-if="$slots.header">
              <slot name="header" />
            </div>

            <div
              v-if="$slots.content"
              class="block px-4 py-2 max-h-full"
              :class="contentClass"
            >
              <slot name="content" />
            </div>

            <!-- Uses option template to render item -->
            <div v-if="$slots['option-template']">
              <MenuItem
                v-for="(option, index) in options"
                :key="index"
              >
                <div
                  class="block px-4 py-1.5 text-sm font-normal"
                  :class="option?.parentClass"
                >
                  <slot
                    name="option-template"
                    :dropdown-option="option"
                  />
                </div>
              </MenuItem>
            </div>

            <!-- Uses value property to render item -->
            <div v-else>
              <MenuItem
                v-for="(option, index) in options"
                :key="option.value"
                v-slot="{ active }"
                v-navigate="option?.goto"
                :disabled="option?.disabled"
              >
                <a
                  v-if="!option.divider"
                  class="cursor-pointer block px-4 py-1.5 text-sm font-normal text-grey-1 hover:text-grey-1"
                  :class="[
                    { 'bg-light-grey-2b': active },
                    option?.disabled ? `text-grey-3 !cursor-default hover:!bg-white hover:!text-grey-3 ${disabledOptionClass}` : '',
                    optionClass
                  ]"
                  @click="onClick(option, $event)"
                >
                  <slot
                    name="option"
                    :option="option"
                    :index="index"
                    :active="active"
                  >
                    <div class="flex items-center">
                      <div
                        v-if="modelValue && isShowSelectedDot"
                        class="rounded-full w-2 h-2 mr-3"
                        :class="{ 'bg-blue-3': isSelected(option) }"
                      />

                      <slot
                        name="option-text"
                        :option="option"
                        :index="index"
                        :active="active"
                      >
                        <VIcon
                          v-if="option?.icon"
                          :icon="option.icon?.name"
                          :size="option.icon?.size"
                          :color="option.icon?.color ? option.icon?.color : 'text-grey-4'"
                          class="mr-2"
                        />

                        <div
                          class="mr-3"
                          :class="option.textClass"
                        >
                          {{ option?.text }}
                        </div>
                      </slot>
                    </div>

                    <div
                      v-if="option?.subtext"
                      class="description-text mt-1"
                    >
                      {{ option?.subtext }}
                    </div>
                  </slot>
                </a>

                <div
                  v-else
                  class="mx-4 border-t border-dashed my-2"
                  :class="dividerClass"
                />
              </MenuItem>
            </div>

            <div
              v-if="$slots.footer"
              class="block px-4 py-2 text-grey-4 break-words text-xxs"
              :class="footerClass"
            >
              <slot name="footer" />
            </div>
          </div>
        </MenuItems>
      </transition>
    </span>
  </Menu>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { helpers } from '@/utils/helpers';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import VIcon from '@/components/VIcon';

const directions = [
  'wsw',
  'w',
  'wnw',
  'nw',
  'nnw',
  'n',
  'nne',
  'ne',
  'ene',
  'e',
  'ese',
  'se',
  'sse',
  's',
  'ssw',
  'sw'
];

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    VIcon
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: [Number, String],
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: [Array, String],
      default: 'text-link'
    },
    buttonContainerClass: {
      type: [Array, String],
      default: ''
    },
    optionClass: {
      type: String,
      default: ''
    },
    disabledOptionClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    },
    menuClass: {
      type: String,
      default: ''
    },
    iconClass: {
      type: [Array, String],
      default: ''
    },
    isStatic: {
      type: Boolean,
      default: false
    },
    forcedOpen: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    responsive: {
      type: Boolean,
      default: false
    },
    rootElem: {
      type: [Object, undefined],
      default: undefined
    },
    direction: {
      type: String,
      default: 'ssw',
      validator: value => {
        return directions.includes(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dividerClass: {
      type: String,
      default: ''
    },
    hoverOpen: {
      type: Boolean,
      default: false
    },
    isShowSelectedDot: {
      type: Boolean,
      default: true
    },
    footerClass: {
      type: String,
      default: ''
    },
    truncateLength: {
      type: Number,
      default: 36
    }

  },
  emits: ['click', 'update:modelValue', 'toggle'],
  setup (props, context) {
    // Refs
    const root = ref(null);
    const menu = ref(null);
    const containerElem = ref(null);
    const rootRef = computed(() => props.rootElem ?? root.value);
    const isHovered = ref(false);

    // Computed
    const position = computed(() => helpers.calcPosition(rootRef.value?.getBoundingClientRect(), menu.value?.getBoundingClientRect(), props.direction, 8, 4, props.fixed, props.responsive));
    const isForcedOpen = computed(() => {
      if (props.hoverOpen) return isHovered.value;

      return props.forcedOpen;
    });

    watch(position, (newPosition) => {
      if (props.responsive && props.fixed && menu.value) {
        const menuRect = menu.value.getBoundingClientRect();
        const yValue = Number.parseInt(newPosition[newPosition.top ? 'top' : 'bottom'].replace('px', ''));
        const y = yValue + menu.value.clientHeight;

        if (y > window.innerHeight) {
          menu.value.style.height = menuRect.height - (y - window.innerHeight) + 'px';
        } else if (y < 0) {
          menu.value.style.height = menuRect.height + y + 'px';
        }
      }
    });

    // Methods
    const onClick = (option, event) => {
      if (option?.disabled) {
        return;
      }

      context.emit('update:modelValue', option.value);
      context.emit('click', option, event);

      if (!option.click) {
        return;
      }

      option.click(option, event);
    };

    const isSelected = option => props.modelValue === option.value;

    let delay = null;
    const onMouseHover = event => {
      clearTimeout(delay);
      if (event.type === 'mouseleave') {
        delay = setTimeout(() => {
          isHovered.value = false;
        }, 150);
      } else {
        isHovered.value = true;
      }
    };

    return {
      root,
      menu,
      containerElem,
      isHovered,
      isForcedOpen,
      position,
      // Methods
      onClick,
      isSelected,
      onMouseHover
    };
  }
};
</script>
