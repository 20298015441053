import { dayjs } from '@/utils/dayjs';
// Constants
import ALL_OPTIONS from '@/constants/options';

const helpers = {
  /**
   * Format an array into a tree
   * @param {Array<Object>} array - the array to format
   * @param {String} parentField - the parent id field name
   * @returns {Array<Object>} - the formatted array
   */
  createTree (array, parentField) {
    const map = array.reduce((acc, cv, index) => ({
      ...acc,
      [cv.id ?? `anon_${index}`]: {
        ...cv,
        children: []
      }
    }), {});

    const tree = [];

    array.forEach((item, index) => {
      if (item[parentField] && map[item[parentField]]) {
        map[item[parentField]].children.push(map[item.id]);
      } else {
        tree.push(map[item.id ?? `anon_${index}`]);
      }
    });

    return tree;
  },
  /**
   * Filter an array of objects by provided string and field, starting at the beginning of words
   * @param {Array<Object>} array - the array to filter
   * @param {String} search - the string to filter by
   * @param {String} field - the array column to filter by
   * @returns {Array<Object>} - the formatted array
   */
  filterByColumnValue (array, search, field) {
    if (!search.length) {
      return array;
    }
    const regex = new RegExp('\\b' + search, 'i');
    return array.filter(item => regex.test(item[field]));
  },
  /**
   * Filters and cleans an array of text values.
   *
   * This function takes an array of objects and filters out objects that have a `texts` property, where `texts` is an array of objects with a `name` property.
   * It also cleans the `name` property of each object within the `texts` array by removing whitespace characters and removes objects with an empty or whitespace-only `name` property.
   *
   * @param {Array} values - The array of objects to be filtered and cleaned.
   * @returns {Array} - An array containing filtered and cleaned objects.
 */
  filterAndCleanTextsValues (values = []) {
    return values.reduce((acc, cv) => {
      if (cv.texts && Array.isArray(cv.texts)) {
        cv.texts = cv.texts.reduce((acc, textObj) => {
          if (textObj.name) {
            const cleanedTextObj = textObj.name.replace(/\s+/g, '');
            if (cleanedTextObj === '') {
              return acc;
            }
          }
          return [
            ...acc,
            textObj
          ];
        }, []);
        if (cv.texts.length > 0) {
          acc.push(cv);
        }
      }
      return acc;
    }, []);
  },
  /**
   * Gets the ancestor ids from items
   * @param {Array} items - the items we want to get their ancestors
   * @param {Array} values - the values being returned. Default as empty array.
   * @param {Array} data - the data being checked against
   * @param {String} parentField - the name of the parent field
   * @returns {Array} values - the ancestor ids
   */
  getAncestorIds (items = [], values = [], data = [], parentField) {
    const ancestors = data.filter(item => items.some(i => i?.[parentField] === item.id));
    const ancestorIds = ancestors.map(x => x.id);

    if (ancestors.length === 0) {
      return values;
    }

    return this.getAncestorIds(ancestors, [...ancestorIds, ...values]);
  },
  /**
   * Gets the descendant ids from items
   * @param {Array} items - the items we want to get their descendants
   * @param {Array} values - the values being returned. Default as empty array.
   * @param {Array} data - the data being checked against
   * @param {String} parentField - the name of the parent field
   * @returns values - the descendant ids
   */
  getDescendantIds (items = [], values = [], data = [], parentField) {
    const descendants = data.filter(item => items.some(i => i.id === item[parentField]));
    const descendantIds = descendants.map(x => x.id);

    if (descendants.length === 0) {
      return values;
    }

    return this.getDescendantIds(descendants, [...descendantIds, ...values]);
  },
  /**
   * Calculate the directional position to display a menu or tooltip, based on root element and menu element.
   * @param {Object} rootRect - the root element the menu displays from
   * @param {Object} menuRect - the menu or tooltip that is displayed
   * @param {String} direction - a compass direction for the menu to display in
   * @param {Number} offsetX - a pixel offset to shift in the x direction
   * @param {Number} offsetY - a pixel offset to shift in the y direction
   * @param {Boolean} fixed - Should the position be relative to the viewport
   * @param {Boolean} responsive - Should the position recalculate if it goes off screen
   * @returns {{top: string, left: string}|boolean|{left: string, bottom: string}|{bottom: string, right: string}|{top: string, right: string}}
   */
  calcPosition (rootRect, menuRect, direction, offsetX = 8, offsetY = 4, fixed = false, responsive = false) {
    if (rootRect && menuRect) {
      const paddedWidth = rootRect.width + offsetX;
      const paddedHeight = rootRect.height + offsetY;
      const menuWidth = menuRect.width + 2;
      const menuHeight = menuRect.height + 2;
      let offset = {};

      switch (direction) {
        case 'sw':
          offset = { top: paddedHeight, right: paddedWidth };
          break;
        case 'wsw':
          offset = { top: 0, right: paddedWidth };
          break;
        case 'w':
          offset = { top: (paddedHeight / 2) - (menuHeight / 2), right: paddedWidth };
          break;
        case 'wnw':
          offset = { bottom: 0, right: paddedWidth };
          break;
        case 'nw':
          offset = { bottom: paddedHeight, right: paddedWidth };
          break;
        case 'nnw':
          offset = { bottom: paddedHeight, right: 0 };
          break;
        case 'n':
          offset = { bottom: paddedHeight, left: (rootRect.width / 2) - (menuWidth / 2) };
          break;
        case 'nne':
          offset = { bottom: paddedHeight, left: 0 };
          break;
        case 'ne':
          offset = { bottom: paddedHeight, left: paddedWidth };
          break;
        case 'ene':
          offset = { bottom: 0, left: paddedWidth };
          break;
        case 'e':
          offset = { top: (rootRect.height / 2) - (menuHeight / 2), left: paddedWidth };
          break;
        case 'ese':
          offset = { top: 0, left: paddedWidth };
          break;
        case 'se':
          offset = { top: paddedHeight, left: paddedWidth };
          break;
        case 'sse':
          offset = { top: paddedHeight, left: 0 };
          break;
        case 's':
          offset = { top: paddedHeight, left: (rootRect.width / 2) - (menuWidth / 2) };
          break;
        case 'ssw':
        default:
          offset = { top: paddedHeight, right: 0 };
          break;
      }

      let isLeft = ('left' in offset);
      let isTop = ('top' in offset);

      if (responsive) {
        const x = isLeft ? rootRect.left + offset.left + menuWidth : rootRect.right - offset.right - menuWidth;
        const y = isTop ? rootRect.top + offset.top + menuHeight : rootRect.bottom - offset.bottom - menuHeight;
        if (x > window.innerWidth || x < 0) {
          // Flip direction
          const oldKey = isLeft ? 'left' : 'right';
          const newKey = isLeft ? 'right' : 'left';
          isLeft = !isLeft;
          delete Object.assign(offset, { [newKey]: offset[oldKey] })[oldKey];
        }
        if (y > window.innerHeight || y < 0) {
          // Flip direction
          const oldKey = isTop ? 'top' : 'bottom';
          const newKey = isTop ? 'bottom' : 'top';
          isTop = !isTop;
          delete Object.assign(offset, { [newKey]: offset[oldKey] })[oldKey];
        }
      }

      if (fixed) {
        const xKey = isLeft ? 'left' : 'right';
        const yKey = isTop ? 'top' : 'bottom';

        offset[xKey] = isLeft ? rootRect.left + offset[xKey] : window.innerWidth - rootRect.right + offset[xKey];
        offset[yKey] = isTop ? rootRect.top + offset[yKey] : window.innerHeight - rootRect.bottom + offset[yKey];
      }

      Object.keys(offset).forEach(key => {
        offset[key] = offset[key] + 'px';
      });

      return offset;
    }

    return false;
  },
  truncate (str = '', length) {
    if (str.length > length) {
      return `${str.slice(0, length)}...`;
    }

    return str;
  },
  isStatusActive (option) {
    return helpers.isFieldValue(option, 'status', ALL_OPTIONS.ACTIVE.value);
  },
  isFieldValue (option, field, value) {
    return option?.[field] === value;
  },
  getFullName (user = {}) {
    const { firstname, lastname } = user ?? {};

    if (firstname && lastname) return `${firstname} ${lastname}`;
    if (firstname) return firstname;
    if (lastname) return lastname;
    return '';
  },
  getInitials (user = {}) {
    const { firstname, lastname } = user ?? {};
    const firstInitial = firstname?.[0]?.toUpperCase();
    const lastInitial = lastname?.[0]?.toUpperCase();

    if (firstInitial && lastInitial) return `${firstInitial}${lastInitial}`;
    if (firstInitial) return firstInitial;
    if (lastInitial) return lastInitial;
    return '';
  },
  getShortName (user = {}) {
    const { firstname, lastname } = user ?? {};

    return firstname && lastname ? `${firstname[0]}. ${lastname}` : '';
  },
  getInitialsFullName (user = {}, maxLength = 20) {
    const name = helpers.getFullName(user);
    return name?.length > maxLength ? helpers.getShortName(user) : name;
  },

  getReorderData (data = []) {
    return data.reduce((acc, item, index) => ({
      ...acc,
      [item?.id]: index + 1
    }), {});
  },
  getUpdateTextsData (texts = []) {
    return texts.reduce((acc, cv) => {
      const { id, ...value } = cv;

      return [...acc, [{ id }, value]];
    }, []);
  },
  getStringifiedUpdateTextsData (texts = []) {
    return JSON.stringify(helpers.getUpdateTextsData(texts));
  },
  truncateHtml (inputHtml = '', length) {
    const truncatedHtml = [];
    let currentLength = 0;
    const tagStack = [];

    for (let i = 0; i < inputHtml.length; i++) {
      const char = inputHtml[i];

      if (char === '<') {
        const tagStart = i;
        const tagEnd = inputHtml.indexOf('>', i);
        if (tagEnd === -1) {
          break; // Unclosed tag, stop truncation
        }
        const tag = inputHtml.slice(tagStart, tagEnd + 1);
        truncatedHtml.push(tag);
        i = tagEnd;
        continue;
      }

      truncatedHtml.push(char);
      currentLength++;

      if (currentLength >= length) {
        truncatedHtml.push('...');
        break;
      }
    }

    while (tagStack.length > 0) {
      const unclosedTag = tagStack.pop();
      truncatedHtml.push(`</${unclosedTag}>`);
    }

    return truncatedHtml.join('');
  },
  isYes (value) {
    return value === ALL_OPTIONS.YES.value;
  },
  validateMimeType (file) {
    const allowedMimeTypes = [
      'image/jpeg',
      'image/png',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
      'image/svg+xml'
    ];
    return allowedMimeTypes.includes(file.type);
  },
  getLatestDate (dates = []) {
    if (!dates?.length) return '';
    return dates.reduce((acc, cv) => dayjs(cv).isAfter(acc) ? cv : acc);
  },
  validateURL (url = '') {
    return url.startsWith('http://') || url.startsWith('https://');
  },
  sortChronologically (a, b, order) {
    if (order === 'a') {
      return dayjs(a).isAfter(b) ? 1 : -1;
    }

    return dayjs(b).isAfter(a) ? 1 : -1;
  },
  sortAlphabetically (a, b, order) {
    if (order === 'a') {
      return a.localeCompare(b);
    }

    return b.localeCompare(a);
  },

  formatNumber (number = 0) {
    return new Intl.NumberFormat().format(number);
  }

};

const install = app => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers };
