<template>
  <div ref="root">
    <Listbox
      v-slot="{ open }"
      :model-value="modelValue"
      as="div"
      :disabled="disabled"
      :multiple="multiple"
      @update:model-Value="$emit('update:modelValue', $event)"
    >
      <ListboxLabel
        class="block text-sm font-semibold text-grey-1"
        :class="[
          { 'field-required': required },
          labelClass
        ]"
      >
        <slot name="label">
          {{ label }}
        </slot>
      </ListboxLabel>

      <div
        class="relative"
        :class="[`mt-${margin}`]"
      >
        <!-- Select Trigger -->
        <ListboxButton v-if="!!$slots['trigger']">
          <slot name="trigger" />
        </ListboxButton>

        <ListboxButton
          ref="button"
          :disabled="hideOptions"
          class="relative w-full cursor-pointer border border-grey-8 bg-white rounded-md shadow-sm px-3 py-2 text-left sm:text-sm"
          :class="[
            { 'h-9': !expandable },
            { '!bg-light-grey-2 border-[#E2E2E2]': readOnly },
            { '!bg-light-grey-2 text-grey-2 !cursor-default': disabled },
            { 'focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-blue-4': !disabled },
            selectClass
          ]"
        >
          <div
            v-if="multiple && items.length > 0"
            :class="[
              { 'flex gap-1 items-center absolute overflow-hidden top-0 bottom-0 left-0 px-3 py-2': !expandable }
            ]"
          >
            <div
              v-for="i in (expandable ? items : items.slice(0, MAX_ITEMS))"
              :key="i"
              class="text-sm rounded-[3px] py-px"
              :class="[
                {'flex items-center truncate pl-2 pr-[.3rem]' : !expandable },
                { 'm-0.5 inline-block max-w-[95%] pl-2 pr-1 inline-flex items-center': expandable },
                { 'bg-[#e1f1ff] text-grey-1': readOnly },
                { 'bg-blue-4 text-white': !readOnly },
                i.tagClass
              ]"
              :style="(i.colour && customTagBg) ? { 'background-color': i.colour } : ''"
            >
              <span :class="[{ 'truncate inline-block align-middle': expandable }]">
                {{ i.text }}
              </span>

              <XIcon
                v-if="!readOnly"
                class="block h-3 ml-1 cursor-pointer text-blue-2 w-[15px] mt-px hover:text-grey-2"
                :class="{ 'inline': expandable }"
                @click="onClickRemoveItem(i.value)"
              />
            </div>

            <span v-if="items.length > MAX_ITEMS && !expandable">
              +{{ items.length - MAX_ITEMS }}
            </span>
          </div>

          <span
            v-else
            class="block truncate"
            :class="item?.itemClass"
          >
            <slot
              name="item"
              :item="item"
            >
              <span v-if="item.text">
                {{ item.text }}
              </span>

              <span
                v-else
                class="text-grey-2 text-sm"
              >
                {{ placeholder }}
              </span>
            </slot>
          </span>

          <span
            v-if="!$slots['trigger'] && !hideOptions"
            class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
          >
            <SelectorIcon
              v-if="!expandable"
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            ref="menu"
            class="z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md p-1 text-base overflow-auto focus:outline-none sm:text-sm"
            :class="[listClass, dropDownDirection, fixed ? 'fixed' : 'absolute']"
            :style="getPosition(open)"
          >
            <div
              v-if="$slots['header-options']"
              class="block px-4 py-2 text-left"
            >
              <slot name="header-options" />
            </div>

            <ListboxOption
              v-for="(option, index) in options"
              :key="option.value"
              v-slot="{ active, selected }"
              :value="option?.value"
              :disabled="option?.disabled"
              class="text-gray-900 cursor-pointer select-none relative py-1.75 pl-3 pr-9"
              :class="[option?.class, hoverOptionClass]"
            >
              <slot
                name="list-item"
                :option="option"
                :index="index"
                :active="active"
                :selected="selected"
              >
                <li
                  v-if="!isSelected(option?.value) || (isSelected(option?.value) && !expandable)"
                  class="select-none relative"
                  :class="[
                    option?.class ?? '',
                    { '!cursor-default text-grey-4': option?.disabled }
                  ]"
                >
                  <span
                    class="block truncate"
                    :class="[selected || option?.subtext ? 'font-semibold' : 'font-medium']"
                  >
                    <slot
                      name="list-item-text"
                      :option="option"
                    >
                      {{ option.text }}
                    </slot>
                  </span>

                  <span
                    v-if="option?.subtext"
                    class="block !text-xs"
                    :class="[active ? option?.activeSubtext ?? 'text-grey-9' : option?.inactiveSubtext ?? 'description-text']"
                  >
                    {{ option.subtext }}
                  </span>
                </li>
              </slot>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ListboxLabel
} from '@headlessui/vue';
import {
  SelectorIcon,
  XIcon
} from '@heroicons/vue/solid';
import { helpers } from '@/utils/helpers';

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    ListboxLabel,
    SelectorIcon,
    XIcon
  },
  props: {
    modelValue: {
      type: [String, Number, Array],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    hoverOptionClass: {
      type: String,
      default: 'hover:bg-light-grey-2'
    },
    selectClass: {
      type: String,
      default: ''
    },
    listClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hideOptions: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    customTagBg: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: false
    },
    margin: {
      type: Number,
      default: 2
    },
    responsive: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    // Constants
    const MAX_ITEMS = 2;

    // Data
    const root = ref(null);
    const menu = ref(null);
    const button = ref(null);

    // Computed
    const items = computed(() => props.options.filter(option => props.modelValue.includes(option.value)));
    const item = computed(() => {
      const selected = props.options.find(option => props.multiple ? props.modelValue.includes(option.value) : option.value === props.modelValue);

      return selected ?? { text: '' };
    });

    const dropDownDirection = computed(() => {
      if (props.responsive && root.value) {
        const selectRect = root.value?.getBoundingClientRect();
        if (window.innerHeight - selectRect.bottom < 300) return 'bottom-full';
      }
      return '';
    });

    const getPosition = () => {
      if (!props.fixed || !button.value || !menu.value) return {};

      let calculatePosition = helpers.calcPosition(button.value?.el?.getBoundingClientRect(), menu.value?.el?.getBoundingClientRect(), 'sse', 8, 4, props.fixed, false);
      const modal = button.value?.$el.closest('[id^=headlessui-dialog-panel-]');
      if (modal) {
        calculatePosition = Object.keys(calculatePosition).reduce((acc, key) => {
          const offsetValue = 'offset' + key.charAt(0).toUpperCase() + key.slice(1);
          acc[key] = (calculatePosition[key].slice(0, -2) - modal[offsetValue]) + 'px';
          return acc;
        }, {});
      }

      return calculatePosition;
    };

    // Methods
    const isSelected = value => props.multiple ? props.modelValue.includes(value) : value === props.modelValue;

    const onClickRemoveItem = value => {
      const values = props.modelValue.filter(x => x !== value);
      context.emit('update:modelValue', values);
    };

    return {
      MAX_ITEMS,
      root,
      menu,
      button,
      item,
      items,
      getPosition,
      isSelected,
      onClickRemoveItem,
      dropDownDirection
    };
  }
};
</script>
