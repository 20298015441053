const state = () => {
  return {
    open: false,
    status: '',
    type: '',
    term: '',
    starred: ''
  };
};

const getters = {
  open: state => state.open,
  status: state => state.status,
  type: state => state.type,
  term: state => state.term,
  starred: state => state.starred,
  updatedStars: state => state.stars
};

const actions = {
  setStatus ({ commit }, status) {
    const statuses = ['Open', 'Completed', 'Cancelled'];

    const statusArray = status.value?.split(',');
    if (status && !statusArray.every(x => statuses.includes(x))) {
      throw new Error(`${status} value need to be of type ${statuses}`);
    }

    commit('SET_STATUS', status);
  },
  setType ({ commit }, type) {
    const types = ['JOB_INPUT', 'GOAL_FEEDBACK', 'JOB_SIGNOFF', 'SCHEDULED_REVIEW', 'TAKE_SURVEY', 'INTERVIEW', 'OPENING', 'CONDUCT_ASSESSMENT_SUBJECT', 'CONDUCT_ASSESSMENT_MANAGER', 'ASSESSMENT_ACKNOWLEDGEMENT', 'SCHEDULE_ASSESSMENT_DISCUSSION'];

    const typeArray = type.value?.split(',');
    if (type && !typeArray.every(x => types.includes(x))) {
      throw new Error(`${type} value need to be of type ${types}`);
    }

    commit('SET_TYPE', type);
  },
  setTerm ({ commit }, term) {
    if (term && typeof term.value !== 'string' && !(term.value instanceof String)) {
      throw new Error(`${term} value need to be a string`);
    }

    commit('SET_TERM', term);
  },
  setStarred ({ commit }, starred) {
    commit('SET_STARRED', starred);
  },
  setOpen ({ commit }, open) {
    commit('SET_OPEN', open);
  },
  updateStars ({ commit }, id) {
    commit('UPDATE_STARS', id);
  }
};

const mutations = {
  SET_STATUS (state, status) {
    state.status = status;
  },
  SET_TYPE (state, type) {
    state.type = type;
  },
  SET_TERM (state, term) {
    state.term = term;
  },
  SET_STARRED (state, starred) {
    state.starred = starred;
  },
  SET_OPEN (state, open) {
    state.open = open;
  },
  UPDATE_STARS (state, task) {
    state.stars = task ? {
      ...state.stars,
      ...task
    } : {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
