<template>
  <div class="min-w-full">
    <Disclosure
      v-slot="{ open }"
      as="nav"
      class="bg-color border-b border-light-grey-3"
    >
      <div class="px-page !p-0">
        <div class="flex items-center justify-between h-14 px-6">
          <div class="flex items-center">
            <div
              v-if="logo"
              class="flex-shrink-0"
            >
              <img
                class="max-h-8 max-w-32"
                :src="logo"
                alt="logo"
              >
            </div>

            <div class="-mr-2 ml-6 flex lg:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton class="inline-flex items-center justify-center menu-active menu-text-color px-3 py-2 rounded-md text-sm leading-none focus:outline-none focus-visible:outline-none">
                {{ $t('menu') }}

                <ChevronDownIcon
                  v-if="!open"
                  class="chev-small -mr-2 ml-1.5"
                />

                <ChevronUpIcon
                  v-else
                  class="chev-small -mr-2 ml-1.5 "
                />
              </DisclosureButton>
            </div>

            <div class="hidden lg:block">
              <div class="ml-7.5 flex items-baseline space-x-4">
                <template
                  v-for="item in navigationItems"
                  :key="item.name"
                >
                  <router-link
                    v-if="!item.children"
                    :to="{ name: item.name }"
                    class="menu-hover menu-text-color px-2 py-1.25 rounded text-sm"
                    :class="{ 'menu-active': isActive(item.name) }"
                    :aria-current="isActive(item.name) ? 'page' : undefined"
                  >
                    {{ item.title }}
                  </router-link>

                  <VDropdown
                    v-else
                    :class="[isActive(item.name) ? 'menu-active rounded-md text-sm':'menu-hover menu-text-color rounded-md']"
                    button-class="menu-hover pl-2 pr-0.75 py-1.25 rounded text-sm"
                    direction="sse"
                    :options="formatChildren(item.children)"
                  >
                    <span :class="[isActive(item.name) ? 'menu-active':'']">
                      {{ item.title }}
                    </span>

                    <ChevronDownIcon
                      class="chev-icon chev-small inline-block ml-0.75"
                      aria-hidden="true"
                    />
                  </VDropdown>
                </template>
              </div>
            </div>
          </div>

          <div>
            <div class="ml-4 flex items-center md:ml-6 gap-2 menu-text-color">
              <div class="relative">
                <button
                  ref="taskRef"
                  type="button"
                  class="nav-icon p-1 relative rounded-full menu-text-color leading-none nav-icon-width"
                  @click="onClick(tabs.TASKS.value)"
                  @mouseenter="showTooltip = true"
                  @mouseleave="showTooltip = false"
                >
                  <VTooltip
                    class="text-sm"
                    :tooltip="$t('tasks',2)"
                    :tip-class="showTooltip ? 'block':'hidden'"
                    responsive
                    direction="ssw"
                  >
                    <VIcon
                      icon="checklist"
                      color="menu-text-color leading-tight"
                    />
                  </VTooltip>

                  <div
                    v-if="tasksCount > 0"
                    class="absolute text-white text-[0.6rem] h-[15px] min-w-[15px] px-1 py-0.75 bottom-4 left-[1.125rem] font-semibold rounded-full bg-red-1"
                  >
                    {{ tasksCount }}
                  </div>
                </button>

                <VFlyout
                  :show="isShowing"
                  :include="include"
                  class="absolute bottom-5 lg:right-[412px] right-[200px]"
                  content-class="!pl-6 !pr-0"
                  @hide="onHide"
                >
                  <template #tabs>
                    <div class="flex justify-between items-center">
                      <VTabs
                        :tabs="displayedTabs"
                        tab-class="!py-4"
                        class="px-3"
                        inactive-tab-class="text-grey-2"
                        @click="onClickTab"
                      />
                      <router-link
                        v-if="activeTab === tabs.TASKS.value"
                        class="pr-3"
                        :to="{ name: 'tasks' }"
                        @click="onHide"
                      >
                        <VIcon
                          icon="open_in_new"
                          color="text-magenta-0"
                          class="mt-[3px]"
                        />
                      </router-link>
                    </div>
                  </template>

                  <template #content>
                    <div class="lg:w-[460px] w-[350px]">
                      <VTasks
                        v-if="activeTab === tabs.TASKS.value"
                        @close="onHide"
                      />

                      <VNotifications
                        v-if="activeTab === tabs.NOTIFICATIONS.value"
                        @close="onHide"
                      />
                    </div>
                  </template>
                </VFlyout>
              </div>

              <button
                ref="notificationRef"
                type="button"
                class="nav-icon relative p-1 rounded-full menu-text-color leading-none nav-icon-width"
                @click="onClick(tabs.NOTIFICATIONS.value)"
                @mouseenter="showTooltip = true"
                @mouseleave="showTooltip = false"
              >
                <VTooltip
                  class="text-sm"
                  :tooltip="$t('notifications', 2)"
                  :tip-class="showTooltip ? 'block':'hidden'"
                  responsive
                  direction="ssw"
                >
                  <VIcon
                    icon="notifications"
                    color="menu-text-color"
                    class="pt-0.5 leading-[1.1875rem]"
                  />
                </VTooltip>

                <div
                  v-if="notificationCount > 0"
                  class="absolute text-white text-[0.6rem] h-[15px] min-w-[15px] px-1 py-0.75 bottom-4 left-[1.125rem] font-semibold rounded-full bg-red-1"
                >
                  {{ notificationCount }}
                </div>
              </button>

              <PendingJobUpdates
                v-if="hasJobUpdatePermission"
                class="nav-icon p-[5px] rounded-full menu-text-color"
              />

              <VDropdown
                v-if="settingsOptions.length > 0"
                :options="settingsOptions"
                class="nav-icon rounded-full nav-icon-width"
                button-class="nav-icon p-1 rounded-full menu-text-color leading-none"
                @mouseenter="showTooltip = true"
                @mouseleave="showTooltip = false"
              >
                <VTooltip
                  class="text-sm"
                  :tooltip="$t('administration')"
                  :tip-class="showTooltip ? 'block':'hidden'"
                  responsive
                  direction="ssw"
                >
                  <VIcon
                    icon="settings"
                    size="text-[20px]"
                    weight="font-light"
                    color="menu-text-color leading-tight pl-px !font-normal"
                    class="-mt-px"
                    @click="showTooltip = false"
                  />
                </VTooltip>
              </VDropdown>

              <!-- Profile dropdown -->
              <Menu
                as="div"
                class="ml-3 relative"
              >
                <div>
                  <VDropdown
                    :options="userNavigation"
                    @mouseenter="showTooltip = true"
                    @mouseleave="showTooltip = false"
                  >
                    <VTooltip
                      class="text-sm"
                      :tooltip="hasPermissions(permissions.MANAGE_MY_ACCOUNT) ? $t('my_profile_tooltip') : ''"
                      :tip-class="showTooltip ? 'block' : 'hidden'"
                      responsive
                      direction="ssw"
                    >
                      <VAvatar
                        :user="user"
                        size="medium"
                        @click="showTooltip = false"
                      />
                    </VTooltip>
                  </VDropdown>
                </div>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem
                      v-for="item in userNavigation"
                      :key="item.name"
                      v-slot="{ active }"
                    >
                      <a
                        :href="item.href"
                        :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                      >
                        {{ item.name }}
                      </a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>

        <!-- Mobile menu content -->
        <DisclosurePanel class="lg:hidden border-t mobile-menu-border-color">
          <div>
            <Disclosure
              v-for="item in navigationItems"
              :key="item.name"
              v-slot="{ open: isOpen }"
              as="div"
              class="px-2 border-b mobile-menu-border-color last:border-b-0"
            >
              <router-link
                v-if="!item.children"
                :to="{ name: item.name }"
                class="px-3 py-2 text-sm mt-0 flex justify-between w-full block text-base font-medium rounded-md"
                :class="[
                  item.current ? 'menu-text-color' : 'text-gray-300 ',
                  { 'menu-active': isActive(item.name) },
                ]"
                :aria-current="isActive(item.name) ? 'page' : undefined"
              >
                <DisclosureButton
                  class=" px-3 py-2 text-sm mt-0"
                  :class="isActive(item.name) ? 'rounded-md menu-active' : 'menu-text-color'"
                  :aria-current="item.current ? 'page' : undefined"
                >
                  {{ item.title }}
                </DisclosureButton>
              </router-link>

              <DisclosureButton
                v-if="item.children"
                :class="[item.current ? 'menu-text-color' : 'text-gray-300 ', 'flex justify-between w-full block text-base font-medium']"
                :aria-current="item.current ? 'page' : undefined"
              >
                <div class="menu-text-color px-6 py-2 text-sm flex justify-between w-full">
                  <span class="py-2">
                    {{ item.title }}
                  </span>

                  <ChevronUpIcon
                    :class="isOpen ? 'duration-200' : 'duration-200 rotate-180'"
                    class="w-5 h-5"
                  />
                </div>
              </DisclosureButton>

              <DisclosurePanel
                class="panel transition-all opacity-0 duration-200"
                :class="isOpen && 'opacity-100'"
              >
                <div
                  v-if="item.children"
                  class="pb-2 px-4"
                >
                  <router-link
                    v-for="childMenu in item.children"
                    :key="childMenu.name"
                    :to="{ name: childMenu.name }"
                    :aria-current="isActive(childMenu.name) ? 'page' : undefined"
                  >
                    <div
                      class="menu-hover menu-text-color px-3 py-2 rounded-md text-sm mb-2"
                      :class="{ 'menu-active': isActive(childMenu.name) }"
                    >
                      {{ childMenu.title }}
                    </div>
                  </router-link>
                </div>
              </DisclosurePanel>
            </Disclosure>
          </div>
        </DisclosurePanel>
      </div>
    </Disclosure>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import store from '@/store';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuItem,
  MenuItems
} from '@headlessui/vue';
// Composables
import useTabs from '@/composables/useTabs';
import usePermissions from '@/composables/usePermissions';
// Components
import VTooltip from '@/components/VTooltip';
import VIcon from '@/components/VIcon';
import VDropdown from '@/components/VDropdown';
import VAvatar from '@/components/VAvatar';
import VFlyout from '@/components/VFlyout';
import VTabs from '@/components/VTabs';
import VTasks from '@/components/VTasks';
import VNotifications from '@/components/VNotifications';
import PendingJobUpdates from '@/components/PendingJobUpdates';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/solid';
import tinycolor from 'tinycolor2';

export default {
  components: {
    VTasks,
    VNotifications,
    PendingJobUpdates,
    VFlyout,
    VTabs,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuItems,
    MenuItem,
    VDropdown,
    VIcon,
    VAvatar,
    ChevronDownIcon,
    ChevronUpIcon,
    VTooltip
  },
  setup () {
    // Misc
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    // Composables
    const { activeTab, onClickTab, setActiveTab, isTabActive } = useTabs();
    const {
      permissions,
      features,
      hasPermissions,
      hasFeatures,
      modules,
      hasModules
    } = usePermissions();

    // Data
    const isShowing = ref(false);
    const taskRef = ref();
    const notificationRef = ref();
    const showTooltip = ref(false);

    // Computed
    const userNavigation = computed(() => [
      {
        text: t('preferences'),
        value: 'preferences',
        click: () => {
          if (hasPermissions(permissions.MANAGE_MY_ACCOUNT)) {
            router.push({ name: 'profile-preferences' });
            return;
          }

          router.push({ name: 'profile-job' });
        },
        icon: {
          name: 'manage_accounts',
          size: 'text-[17px]',
          color: 'text-grey-1'
        }
      },
      {
        text: t('job_details'),
        value: 'job_details',
        click: () => {
          router.push({ name: 'profile-job-description' });
        },
        icon: {
          name: 'description',
          size: 'text-[17px]',
          color: 'text-grey-1'
        }
      },
      {
        text: t('logout'),
        value: 'logout',
        click: () => {
          store.dispatch('user/logout', router);
        },
        icon: {
          name: 'logout',
          size: 'text-[17px]',
          color: 'text-grey-1'
        }
      }
    ].filter(x => x?.visible ?? true));

    const tabs = computed(() => ({
      TASKS: {
        text: t('tasks', 2),
        value: 'tasks'
      },
      NOTIFICATIONS: {
        text: t('notifications', 2),
        value: 'notifications'
      }
    }));

    const baseURL = computed(() => store.getters['client/api_url']);
    const logo = computed(() => store.getters['client/logo'] ? `${baseURL.value}/files/app-logo?${new Date().getTime()}` : null);

    const include = computed(() => {
      if (isTabActive(tabs.value.TASKS.value)) return [taskRef.value];
      if (isTabActive(tabs.value.NOTIFICATIONS.value)) return [notificationRef.value];

      return [];
    });

    const hasJobUpdatePermission = computed(() => {
      const hasManageLibraries = hasPermissions(permissions.MANAGE_LIBRARIES);
      const hasManageJobs = hasPermissions(permissions.MANAGE_JOBS);
      const hasManageContent = hasPermissions(permissions.CONTENT_ADMINISTRATOR);

      return hasManageLibraries || hasManageJobs || hasManageContent;
    });

    const settingsOptions = computed(() => [
      {
        text: t('user_management'),
        value: 'user_management',
        visible: hasPermissions(permissions.USERS_ADMINISTRATOR),
        goto: 'user-management',
        icon: {
          name: 'group',
          size: 'text-[18px]',
          color: 'text-grey-1'
        }
      },
      {
        text: t('general_administration'),
        value: 'general_administration',
        visible: [
          permissions.ACCOUNT_ADMINISTRATOR,
          permissions.COMMUNICATIONS_ADMINISTRATOR,
          permissions.MANAGE_LOCATIONS,
          permissions.CONTENT_ADMINISTRATOR
        ].some(x => hasPermissions(x)),
        goto: 'administration',
        icon: {
          name: 'settings',
          size: 'text-[18px]',
          color: 'text-grey-1'
        }
      },
      {
        text: t('audit_logs', 2),
        value: 'audit_logs',
        visible: hasPermissions(permissions.ACCESS_AUDIT_LOGS),
        goto: 'audit-logs',
        icon: {
          name: 'contract',
          size: 'text-[18px]',
          color: 'text-grey-1'
        }
      }
    ].filter(x => x?.visible ?? true));

    const displayedTabs = computed(() => {
      return Object.keys(tabs.value).map(key => ({
        text: tabs.value[key].text,
        value: tabs.value[key].value,
        active: activeTab.value === tabs.value[key].value
      }));
    });

    const navigation = computed(() => [
      {
        title: t('home'),
        name: 'home'
      },
      {
        title: t('my_team'),
        name: 'my-teams',
        visible: hasPermissions(permissions.MANAGE_PEOPLE)
      },
      {
        title: t('organization'),
        name: 'organization',
        children: [
          {
            title: t('org_chart'),
            name: 'organization-org-chart'
          }
        ]
      },
      {
        title: t('contents'),
        name: 'content',
        children: [
          {
            title: t('jobs', 2),
            name: 'content-jobs',
            visible: hasPermissions(permissions.ACCESS_JOBS) && hasModules(modules.JOBS)
          },
          {
            title: t('libraries', 2),
            name: 'content-libraries',
            visible: hasPermissions(permissions.ACCESS_LIBRARIES) || hasPermissions(permissions.MANAGE_INTERVIEWS) || hasPermissions(permissions.MANAGE_INTERVIEW_QUESTIONS) || hasPermissions(permissions.CONTENT_ADMINISTRATOR)
          },
          {
            title: t('interviews', 2),
            name: 'content-interviews',
            visible: hasModules(modules.INTERVIEWS) && (hasPermissions(permissions.ACCESS_INTERVIEW_GUIDE_TEMPLATES) || hasPermissions(permissions.MANAGE_INTERVIEWS) || hasPermissions(permissions.CONTENT_ADMINISTRATOR))
          },
          {
            title: t('surveys', 2),
            name: 'content-surveys',
            visible: hasPermissions(permissions.MANAGE_SURVEYS) && hasModules(modules.SURVEYS)
          },
          {
            title: t('reviews', 2),
            name: 'content-reviews',
            visible: hasModules(modules.REVIEWS) && (hasPermissions(permissions.ACCESS_REVIEWS) || hasPermissions(permissions.MANAGE_REVIEWS)),
            children: [
              {
                title: t('manage_reviews'),
                name: 'content-reviews',
                visible: hasPermissions(permissions.MANAGE_REVIEWS)
              },
              {
                title: t('my_reviews'),
                name: 'content-reviews-my-reviews-index',
                visible: hasPermissions(permissions.ACCESS_REVIEWS)
              }
            ].filter(item => item?.visible ?? true)
          }
        ]
      },
      {
        title: t('analytics', 2),
        name: 'analytics',
        visible: hasPermissions(permissions.ACCESS_ANALYTICS) && hasFeatures(features.ANALYTICS)
      }
    ]);

    const navigationItems = computed(() => {
      const CONTENT_CHILD_MENU_OPTIONS = [
        'content-jobs',
        'content-libraries',
        'content-interviews',
        'content-surveys',
        'content-reviews',
        'content-learning-resources'
      ];

      const isVisible = item => item?.visible ?? true;
      const filterContent = item => isVisible(item) && CONTENT_CHILD_MENU_OPTIONS.includes(item.name);
      const menuItems = navigation.value.filter(isVisible);

      if (menuItems.length <= 5) {
        return menuItems.reduce((filterOption, item) => {
          if (item.name === 'home') {
            filterOption.push(item);
          }

          if (item.name === 'my-teams') {
            filterOption.push(item);
          }

          if (item.name === 'analytics') {
            filterOption.push(item);
          }

          if (item.name === 'content') {
            filterOption.push(...item.children.filter(filterContent));
          }

          return filterOption;
        }, []);
      }

      return menuItems.map(item => ({
        ...item,
        children: item.name === 'content'
          ? item.children.filter(filterContent)
          : item.children
      }));
    });

    const adminHeading = computed(() => {
      if (route.path.includes('administration')) {
        return t('administration');
      }

      if (route.path.includes('user-management')) {
        return t('user_management');
      }

      return '';
    });

    const bgColor = computed(() => store.getters['client/header_background']);
    const textColor = computed(() => store.getters['client/header_foreground']);
    const user = computed(() => store.getters['user/user']);
    const tasksCount = computed(() => store.getters['user/open_tasks_count']);
    const areTasksOpen = computed(() => store.getters['tasks/open']);
    const notificationCount = computed(() => store.getters['user/unseen_notifications_count']);
    const activeMenuBgColor = computed(() => adjustLuminosity(bgColor.value, 0.5, 20));

    const activeMenuTextColor = computed(() => {
      const color = tinycolor(activeMenuBgColor.value);
      if (color.isDark()) {
        return textColor.value;
      } else {
        return '#000000';
      }
    });

    const mobileMenuBorderColor = computed(() => {
      if (tinycolor(activeMenuBgColor.value).isDark()) {
        return 'rgba(255, 255, 255, 0.5)';
      } else {
        return 'rgba(0, 0, 0, 0.5)';
      }
    });

    // Methods
    const isActive = name => route.matched.some(r => r.name === name);

    const formatChildren = children => {
      return children.reduce((acc, child) => {
        if (!child?.permission || hasPermissions(child.permission)) {
          return [
            ...acc,
            {
              text: child.title,
              value: child.name,

              click: () => {
                router.push({ name: child.name });
              }
            }
          ];
        }

        return acc;
      }, []);
    };

    const onClick = value => {
      showTooltip.value = false;
      isShowing.value = !isShowing.value;
      setActiveTab(value);
    };

    const onHide = () => {
      isShowing.value = false;
      store.dispatch('tasks/setOpen', false);
    };

    const adjustLuminosity = (color, saturationThreshold, luminosityAdjustment) => {
      const tColor = tinycolor(color);
      const hsl = tColor.toHsl();
      const saturation = hsl.s;
      let adjustedColor = color;

      if (tColor.isLight()) {
        adjustedColor = '#ededed';
      } else if (saturation < saturationThreshold) {
        adjustedColor = tinycolor(color).lighten(luminosityAdjustment).toString();
      } else {
        adjustedColor = tinycolor(color).darken(luminosityAdjustment).toString();
      }
      return adjustedColor;
    };

    // Watchers
    watch(areTasksOpen, newValue => {
      if (newValue) {
        isShowing.value = newValue;
        setActiveTab(tabs.value.TASKS.value);
      }
    });

    return {
      permissions,
      logo,
      include,
      taskRef,
      notificationRef,
      hasJobUpdatePermission,
      tasksCount,
      user,
      bgColor,
      textColor,
      settingsOptions,
      userNavigation,
      navigationItems,
      isActive,
      formatChildren,
      adminHeading,
      tabs,
      isShowing,
      displayedTabs,
      onClick,
      onHide,
      // usePermissions
      hasPermissions,
      // useTabs
      activeTab,
      onClickTab,
      notificationCount,
      activeMenuBgColor,
      activeMenuTextColor,
      mobileMenuBorderColor,
      showTooltip
    };
  }
};
</script>

<style scoped lang="scss">
  .bg-color {
    background-color: v-bind('bgColor');
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.03);
  }
  .menu-text-color {
    color: v-bind('textColor');
  }
  .menu-text-color:hover {
    color: v-bind('textColor');
  }
  /* TODO : use this in futute https: //www.npmjs.com/package/lighten-darken-color */
  .active,
  .nav-icon:hover {
    background-color: v-bind(activeMenuBgColor) !important;
  }
  .nav-icon-width {
    height: 30px;
    width: 30px;
  }
  .menu-active{
    background-color: v-bind(activeMenuBgColor) !important;
    color: v-bind(activeMenuTextColor) !important;
  }

  .menu-hover:hover {
    transition: all 0.3s ease;
    background-color: v-bind(activeMenuBgColor);
    color: v-bind(activeMenuTextColor);
  }
  .mobile-menu-border-color{
    border-color: v-bind(mobileMenuBorderColor) !important;
  }
</style>
