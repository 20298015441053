import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from '@/store';
import middlewarePipeline from './middleware/middlewarePipeline';

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

router.beforeEach(async (to, from, next) => {
  router.previousUrl = from;
  // Taken from https://blog.logrocket.com/vue-middleware-pipelines/ with modifications
  if (to.matched.every(match => !match.meta.middleware)) {
    return next();
  }

  const middleware = to.matched.reduce((acc, cv) => {
    return cv.meta.middleware ? [...acc, ...cv.meta.middleware] : acc;
  }, []);

  const context = {
    to,
    from,
    next,
    store
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
    nextVue: context.next
  });
});

export default router;
