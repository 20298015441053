import ServiceUtil from '@/services/hrsg/ServiceUtil';

class AuthenticationService extends ServiceUtil {
  endpoint = 'platform.authentication';

  async getLoginType (data) {
    return await this.request({
      endpoint: `${this.endpoint}.get-login-type`,
      data
    });
  }

  async login (data) {
    return await this.request({
      endpoint: `${this.endpoint}.login`,
      data
    });
  }

  async loginSSO (data) {
    return await this.request({
      endpoint: `${this.endpoint}.login-sso`,
      data
    });
  }

  async resend2FACode (data) {
    return await this.request({
      endpoint: `${this.endpoint}.resend-2fa-code`,
      data
    });
  }

  async logout (data) {
    return await this.request({
      endpoint: `${this.endpoint}.logout`,
      data
    });
  }

  async resetPassword (data) {
    return await this.request({
      endpoint: `${this.endpoint}.password-reset`,
      data
    });
  }

  async passwordChange (data) {
    return await this.request({
      endpoint: `${this.endpoint}.password-change`,
      data
    });
  }

  async sendOtp (data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.send-otp`,
      data
    });

    return response?.payload?.message;
  }
}

export default new AuthenticationService();
