// Services
import ReviewService from '@/services/hrsg/reviews/ReviewService';
import ReviewAdministratorService from '@/services/hrsg/reviews/ReviewAdministratorService';
import AssessmentService from '@/services/hrsg/reviews/AssessmentService';
import NotificationRecipientService from '@/services/hrsg/reviews/NotificationRecipientService';
import ResultAccessService from '@/services/hrsg/reviews/ResultAccessService';

const getUserPayload = (selected, updated) => {
  const create = updated.reduce((acc, cv) => {
    if (selected.some(x => x?.subject_id === cv)) return acc;

    return [
      ...acc,
      cv
    ];
  }, []);

  const remove = selected.reduce((acc, cv) => {
    if (updated.some(x => x === cv?.subject_id)) return acc;

    return [
      ...acc,
      cv?.id
    ];
  }, []);

  return {
    create,
    remove
  };
};

const state = () => ({
  review: {},
  isSaving: false,
  hasSaved: false,
  actionButtonState: '',
  setupProcessStatus: 'Draft'
});

const getters = {
  review: state => state.review,
  isSaving: state => state.isSaving,
  hasSaved: state => state.hasSaved,
  actionButtonState: state => state.actionButtonState,
  setupProcessStatus: state => state.setupProcessStatus
};

const actions = {
  async get (context, id) {
    const reviewId = id ?? context.state.review?.id;

    if (!reviewId) return;

    const response = await ReviewService.read(reviewId, [
      'administrators',
      'assessments',
      'notificationRecipients',
      'resultAccesses',
      'specificJob',
      'levels'
    ]);
    context.commit('SET_REVIEW', response);
  },
  async update (context, data) {
    context.commit('SET_HAS_SAVED', false);
    context.commit('SET_IS_SAVING', true);
    await ReviewService.update(context?.state?.review?.id, data);
    context.commit('SET_IS_SAVING', false);
    context.commit('SET_HAS_SAVED', true);
  },
  async delete (context, id) {
    await ReviewService.delete(id ?? context?.state?.review?.id);
    context.commit('SET_REVIEW', null);
  },
  async addAdministrator (context, data) {
    return await ReviewAdministratorService.create({
      ...data,
      review_id: context?.state?.review?.id
    });
  },
  async addRecipient (context, data) {
    return await NotificationRecipientService.create({
      ...data,
      review_id: context?.state?.review?.id
    });
  },
  async addReviewer (context, data) {
    return await ResultAccessService.create({
      ...data,
      review_id: context?.state?.review?.id
    });
  },
  async updateAdministrators (context, event) {
    context.commit('SET_HAS_SAVED', false);
    context.commit('SET_IS_SAVING', true);

    ReviewAdministratorService.setPathvars({ reviewId: context?.state?.review?.id });

    const payload = getUserPayload(context?.state?.review?.administrators, event);

    if (payload?.create?.length) {
      await Promise.all(payload?.create.map(id => context.dispatch('addAdministrator', id)));
    }

    if (payload?.remove?.length) {
      await ReviewAdministratorService.delete(payload?.remove);
    }

    context.commit('SET_IS_SAVING', false);
    context.commit('SET_HAS_SAVED', true);
  },
  async updateAssessments (context, event) {
    context.commit('SET_HAS_SAVED', false);
    context.commit('SET_IS_SAVING', true);

    AssessmentService.setPathvars({ reviewId: context?.state?.review?.id });

    const payload = getUserPayload(context?.state?.review?.assessments, event?.event);

    if (payload?.create?.length) {
      const apiPayload = {
        subject_ids: payload?.create?.join()
      };
      if (event?.ad_hoc_subject) {
        apiPayload.ad_hoc_subject = event.ad_hoc_subject;
      }

      await AssessmentService.create(apiPayload);
    }

    if (payload?.remove?.length) {
      await AssessmentService.delete(payload?.remove, { action: 'REMOVE_SUBJECT' });
    }

    context.commit('SET_IS_SAVING', false);
    context.commit('SET_HAS_SAVED', true);
  },
  async updateNotificationRecipients (context, event) {
    context.commit('SET_HAS_SAVED', false);
    context.commit('SET_IS_SAVING', true);

    NotificationRecipientService.setPathvars({ reviewId: context?.state?.review?.id });

    const payload = getUserPayload(context?.state?.review?.notification_recipients, event);

    if (payload?.create?.length) {
      await Promise.all(payload?.create.map(id => context.dispatch('addRecipient', id)));
    }

    if (payload?.remove?.length) {
      await NotificationRecipientService.delete(payload?.remove);
    }

    context.commit('SET_IS_SAVING', false);
    context.commit('SET_HAS_SAVED', true);
  },
  async updateResultAccesses (context, event) {
    context.commit('SET_HAS_SAVED', false);
    context.commit('SET_IS_SAVING', true);

    ResultAccessService.setPathvars({ reviewId: context?.state?.review?.id });

    const payload = getUserPayload(context?.state?.review?.result_accesses, event);

    if (payload?.create?.length) {
      await Promise.all(payload?.create.map(id => context.dispatch('addReviewer', id)));
    }

    if (payload?.remove?.length) {
      await ResultAccessService.delete(payload?.remove);
    }

    context.commit('SET_IS_SAVING', false);
    context.commit('SET_HAS_SAVED', true);
  },
  setActionButtonState (context, value) {
    context.commit('SET_ACTION_BTN_STATE', value);
  },
  setSetupProcessStatus (context, value) {
    context.commit('SET_SETUP_PROCESS_STATUS', value);
  }
};

const mutations = {
  SET_REVIEW (state, value) {
    state.review = value;
  },
  SET_IS_SAVING (state, value) {
    state.isSaving = value;
  },
  SET_HAS_SAVED (state, value) {
    state.hasSaved = value;
  },
  SET_ACTION_BTN_STATE (state, value) {
    state.actionButtonState = value?.state;
    localStorage.setItem(`review_action_btn_state_${value?.id}`, value?.state);
  },
  SET_SETUP_PROCESS_STATUS (state, value) {
    state.setupProcessStatus = value?.status;
    localStorage.setItem(`setup_status_review_${value?.id}`, value?.status);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
