import i18n from '@/i18n';
// Composables
import useOptions from '@/composables/useOptions';
import useTexts from '@/composables/useTexts';
// Constants
import tableKeys from '@/constants/tableKeys';
import filters from '@/constants/filters';
import permissions from '@/constants/permissions';
// Services
import JobService from '@/services/hrsg/content/jobs/JobService';
import JobSalaryService from '@/services/hrsg/administration/JobSalaryService';
import JobTypeService from '@/services/hrsg/administration/JobTypeService';
import CompetencyLevelService from '@/services/hrsg/content/libraries/CompetencyLevelService';
import ScaleService from '@/services/hrsg/administration/ScaleService';

const formatOptions = (options, text) => [
  {
    text: text ?? t('none'),
    value: 'none'
  },
  ...options.map(x => ({
    ...x,
    text: getText(x?.texts, 'name'),
    value: x?.id
  }))
];

const t = i18n.global.t;
const { getText } = useTexts();
const { allOptions, getLocationsOptions, getJobLevelOptions, getCandidatesLatestStatusOptions } = useOptions();

export default {
  [tableKeys.LEVEL_ANALYSIS_COMPETENCIES]: [
    filters.SPLIT_TEXT_NAME,
    {
      ...filters.JOB_GROUPS,
      field: 'job_group_ids'
    }
  ],
  [tableKeys.LEVEL_ANALYSIS_SKILLS]: [
    filters.SPLIT_TEXT_NAME,
    {
      ...filters.JOB_GROUPS,
      field: 'job_group_ids'
    }
  ],
  [tableKeys.SURVEYS]: [
    filters.SPLIT_TEXT_NAME,
    {
      ...filters.STATUSES,
      getOptions: () => [
        {
          ...allOptions.value.DRAFT,
          showRing: true,
          class: 'ml-px'
        },
        {
          ...allOptions.value.SCHEDULED,
          class: 'ml-px'
        },
        {
          ...allOptions.value.LIVE,
          class: 'ml-px'
        },
        {
          ...allOptions.value.CLOSED,
          overwriteClass: '!bg-grey-6 !border-grey-6',
          class: 'ml-px'
        }
      ].map(x => ({ ...x, status: x?.value }))
    }
  ],
  [tableKeys.USERS]: [
    {
      ...filters.NAME,
      fields: [
        'firstname',
        'lastname',
        'employee_id'
      ],
      placeholder: t('search_name_or_id')
    },
    {
      ...filters.DEPARTMENTS,
      field: 'department_ids'
    },
    {
      ...filters.STATUSES,
      getOptions: () => [
        allOptions.value.ACTIVE,
        allOptions.value.SUSPENDED,
        allOptions.value.DEACTIVATED
      ].map(x => ({ ...x, status: x?.value }))
    },
    filters.USER_EXCEPTION,
    filters.ADDED,
    filters.PERMISSION_SETS
    // filters.LICENSES
  ],
  [tableKeys.POSITIONS]: [
    filters.SPLIT_TEXT_NAME,
    filters.DEPARTMENTS,
    filters.POSITION_EXCEPTION
  ],
  // Reviews
  [tableKeys.REVIEWS]: [
    filters.SPLIT_TEXT_NAME,
    {
      ...filters.STATUSES,
      getOptions: () => [
        {
          ...allOptions.value.DRAFT,
          showRing: true,
          class: 'ml-px !w-2.5 !h-2.5'
        },
        {
          ...allOptions.value.SCHEDULED,
          class: 'ml-px'
        },
        {
          ...allOptions.value.LIVE,
          class: 'ml-px'
        },
        {
          ...allOptions.value.CLOSED,
          overwriteClass: '!bg-grey-6 !border-grey-6',
          class: 'ml-px'
        }
      ].map(x => ({ ...x, status: x?.value }))
    }
  ],
  [tableKeys.ASSESSMENTS]: [
    {
      ...filters.SPLIT_TEXT_NAME,
      localeKey: '',
      fields: ['subject_name'],
      position: 1,
      class: 'w-96 !-mt-2 mr-2'
    },
    {
      ...filters.ASSESSMENTS_DEPARTMENTS,
      field: 'department_ids',
      position: 2
    },
    {
      ...filters.ASSESSMENTS_STATUSES,
      position: 3
    }
  ],
  [tableKeys.PARTICIPANTS]: [
    {
      ...filters.SPLIT_TEXT_NAME,
      localeKey: '',
      position: 1,
      fields: ['participant_name'],
      class: 'w-96 !-mt-2 mr-2'
    },
    {
      ...filters.PARTICIPANTS_DEPARTMENTS,
      field: 'department_ids',
      position: 2
    },
    {
      ...filters.PARTICIPANTS_ROLES,
      field: 'participant_type',
      position: 3
    },
    {
      ...filters.PARTICIPANTS_STATUSES,
      position: 4
    }
  ],
  // Content
  [tableKeys.JOB_SIGNOFFS]: [
    {
      ...filters.NAME,
      fields: ['employee'],
      placeholder: t('search_employee'),
      ignoreSplit: true
    },
    {
      ...filters.DEPARTMENTS,
      field: 'department'
    },
    {
      component: 'FilterSignoffsStatuses',
      key: 'Statuses',
      localeKey: ['statuses', 2],
      getOptions: () => [
        {
          ...allOptions.value.TO_DO,
          status: allOptions.value.TO_DO.value,
          tooltipClass: '!w-48',
          tooltip: t('to_do_description'),
          field: 'status',
          class: 'ml-px'
        },
        {
          ...allOptions.value.REQUESTED,
          status: allOptions.value.REQUESTED.value,
          tooltipClass: '!w-48',
          tooltip: t('requested_description'),
          class: 'ml-px',
          field: 'status',
          showRing: true
        },
        {
          ...allOptions.value.CURRENT,
          status: allOptions.value.CURRENT.value,
          tooltipClass: '!w-48',
          tooltip: t('current_description'),
          field: 'status',
          class: 'ml-px'
        },
        {
          ...allOptions.value.EXPIRED,
          status: allOptions.value.EXPIRED.value,
          tooltipClass: '!w-48',
          tooltip: t('expired_description'),
          field: 'status',
          class: 'ml-px'
        }
      ]
    },
    {
      ...filters.FORWARD_DATE,
      key: 'DUE_DATE',
      localeKey: ['signoff_due_date'],
      field: 'due_at'
    },
    {
      ...filters.DATE,
      localeKey: ['last_signoff_date'],
      field: 'signed_at'
    },
    {
      ...filters.FORWARD_DATE,
      key: 'EXPIRY_DATE',
      localeKey: ['signoff_expiry_date'],
      field: 'expires_at'
    }
  ],
  [tableKeys.JOBS]: [
    {
      ...filters.CUSTOM,
      display: false
    },
    filters.SPLIT_TEXT_NAME,
    {
      ...filters.JOB_GROUPS,
      property: 'JOB_GROUP'
    },
    {
      key: 'JobStatuses',
      component: 'FilterJobsStatuses',
      getOptions: () => [
        allOptions.value.DRAFT,
        allOptions.value.PUBLISHED
      ],
      permissions: [permissions.MANAGE_JOBS]
    },
    {
      key: 'MyJobs',
      component: 'FilterMyJobs',
      localeKey: ['my_jobs', 2],
      getOptions: () => [
        {
          text: t('starred'),
          value: true,
          field: 'starred'
        },
        {
          text: t('owners'),
          value: true,
          field: 'owner'
        }
      ]
    },
    filters.UPDATED,
    {
      key: 'LAST_PUBLISHED',
      component: 'FilterDate',
      name: t('last_published'),
      label: t('last_published'),
      filterKey: 'PublishedDate',
      field: 'published_at',
      optional: true,
      display: false
    },
    {
      key: 'REPORTS_TO',
      component: 'FilterAutocomplete',
      label: t('reports_to'),
      field: 'reports_to',
      optional: true,
      display: false,
      getOptions: async (value = '') => {
        if (!value) {
          return [
            {
              text: t('none'),
              value: 'none'
            }
          ];
        }

        const options = await JobService.index({
          ro_f: JSON.stringify([
            {
              f: 'texts.name',
              c: 'LIKE',
              v: [value]
            }
          ])
        });

        return formatOptions(options);
      }
    },
    {
      key: 'LOCATIONS',
      component: 'FilterCheckboxes',
      field: 'job_locations',
      label: t('locations', 2),
      hasSearch: true,
      optional: true,
      display: false,
      getOptions: async () => {
        const options = await getLocationsOptions();

        return formatOptions(options, t('no_locations'));
      }
    },
    {
      key: 'JOB_CODE',
      filterKey: 'JobCode',
      component: 'FilterText',
      label: t('job_codes'),
      optional: true,
      display: false,
      fields: ['code']
    },
    {
      key: 'SALARY',
      component: 'FilterCheckboxes',
      field: 'salary_id',
      optional: true,
      display: false,
      getOptions: async () => {
        const options = await JobSalaryService.index({ ro_p: -1 });

        return formatOptions(options, t('no_ranges'));
      }
    },
    {
      key: 'JOB_LEVEL',
      component: 'FilterCheckboxes',
      field: 'job_level_id',
      optional: true,
      display: false,
      getOptions: async () => {
        const options = await getJobLevelOptions();

        return formatOptions(options, t('no_levels'));
      }
    },
    {
      key: 'JOB_TYPE',
      component: 'FilterCheckboxes',
      field: 'job_types',
      optional: true,
      display: false,
      getOptions: async () => {
        const options = await JobTypeService.index({ ro_p: -1 });

        return formatOptions(options, t('no_types'));
      }
    },
    {
      key: 'competencies',
      component: 'FilterLibraryItems',
      optional: true,
      display: false,
      reusable: true,
      field: 'content_competencies',
      fieldValue: 'competency_id',
      fieldLevelValue: 'competency_level_ids',
      getOptions: async (id) => {
        CompetencyLevelService.setPathvars({ competency_id: id });
        const options = await CompetencyLevelService.index({ ro_p: -1 });

        return formatOptions(options, t('no_levels'));
      }
    },
    {
      key: 'skills',
      component: 'FilterLibraryItems',
      optional: true,
      display: false,
      reusable: true,
      field: 'content_skills',
      fieldValue: 'skill_id',
      fieldLevelValue: 'skill_level_ids',
      getOptions: async () => {
        const scaleService = new ScaleService();
        const scale = await scaleService.getScaleByHandle('SKILLS');

        if (scale?.enabled === allOptions.value.NO.value) return [];

        const service = new ScaleService('administration.scales.skill');
        const options = await service.index({ ro_p: -1 });

        return formatOptions(options, t('no_levels'));
      }
    }
  ],
  [tableKeys.JOBS_ARCHIVE]: [
    filters.TEXT_NAME,
    filters.JOB_GROUPS
  ],
  // Library Archives
  [tableKeys.RESPONSIBILITY_ARCHIVE]: [
    filters.TEXT_NAME,
    filters.RESPONSBILITY_GROUPS
  ],
  [tableKeys.WORKING_CONDITIONS_ARCHIVE]: [
    filters.TEXT_NAME
  ],
  [tableKeys.EXPERIENCES_ARCHIVE]: [
    filters.TEXT_NAME
  ],
  [tableKeys.COMPETENCY_ARCHIVE]: [
    filters.TEXT_NAME,
    filters.COMPETENCY_GROUPS
  ],
  [tableKeys.LANGUAGES_ARCHIVE]: [
    filters.TEXT_NAME
  ],
  [tableKeys.CERTIFICATION_ARCHIVE]: [
    filters.SPLIT_TEXT_NAME,
    filters.CERTIFICATION_GROUPS
  ],
  [tableKeys.ADDITIONAL_INFORMATION_ARCHIVE]: [
    filters.SPLIT_TEXT_NAME,
    filters.ADDITIONAL_INFORMATION_GROUPS
  ],
  [tableKeys.EDUCATION_ARCHIVE]: [
    filters.SPLIT_TEXT_NAME,
    filters.EDUCATION_GROUPS
  ],
  [tableKeys.SKILLS_ARCHIVE]: [
    filters.SPLIT_TEXT_NAME,
    filters.SKILL_GROUPS
  ],
  // Libraries
  [tableKeys.RESPONSIBILITIES]: [
    filters.SPLIT_TEXT_NAME,
    filters.RESPONSBILITY_GROUPS,
    filters.LIBRARY_STATUS,
    filters.UPDATED
  ],
  [tableKeys.COMPETENCIES]: [
    filters.SPLIT_TEXT_NAME,
    filters.COMPETENCY_GROUPS,
    {
      key: 'Statuses',
      component: 'FilterCompetenciesStatuses',
      getOptions: () => [
        allOptions.value.DRAFT,
        allOptions.value.PUBLISHED
      ],
      permissions: [permissions.MANAGE_LIBRARIES]
    },
    filters.UPDATED,
    filters.TAGS
  ],
  [tableKeys.SKILLS]: [
    filters.SPLIT_TEXT_NAME,
    filters.SKILL_GROUPS,
    filters.LIBRARY_STATUS,
    filters.UPDATED,
    filters.TAGS
  ],
  [tableKeys.LANGUAGES]: [
    filters.SPLIT_TEXT_NAME,
    filters.LIBRARY_STATUS,
    filters.UPDATED
  ],
  [tableKeys.EXPERIENCES]: [
    filters.SPLIT_TEXT_NAME,
    filters.LIBRARY_STATUS,
    filters.UPDATED,
    filters.TAGS
  ],
  [tableKeys.WORKING_CONDITIONS]: [
    filters.SPLIT_TEXT_NAME,
    filters.LIBRARY_STATUS,
    filters.UPDATED,
    filters.TAGS
  ],
  [tableKeys.CERTIFICATION]: [
    filters.SPLIT_TEXT_NAME,
    filters.CERTIFICATION_GROUPS,
    filters.LIBRARY_STATUS,
    filters.UPDATED,
    filters.TAGS
  ],
  [tableKeys.EDUCATION]: [
    filters.SPLIT_TEXT_NAME,
    filters.EDUCATION_GROUPS,
    filters.LIBRARY_STATUS,
    filters.UPDATED,
    filters.TAGS
  ],
  [tableKeys.ADDITIONAL_INFORMATION]: [
    filters.SPLIT_TEXT_NAME,
    filters.ADDITIONAL_INFORMATION_GROUPS,
    filters.LIBRARY_STATUS,
    filters.UPDATED,
    filters.TAGS
  ],
  // Warehouse
  [tableKeys.WAREHOUSE_COMPETENCIES]: [
    {
      ...filters.SPLIT_TEXT_NAME,
      fields: ['texts.original_name']
    },
    filters.WAREHOUSE_COMPETENCY_GROUPS,
    filters.WAREHOUSE_STATUS,
    filters.WAREHOUSE_DOCUMENTS_RECENT_UPDATES
  ],
  [tableKeys.WAREHOUSE_RESPONSIBILITIES]: [
    {
      ...filters.SPLIT_TEXT_NAME,
      fields: ['texts.original_name']
    },
    filters.WAREHOUSE_RESPONSBILITY_GROUPS,
    filters.WAREHOUSE_STATUS,
    filters.WAREHOUSE_FLAT_ITEMS_RECENT_UPDATES
  ],
  [tableKeys.WAREHOUSE_SKILLS]: [
    {
      ...filters.SPLIT_TEXT_NAME,
      fields: ['texts.original_name']
    },
    filters.WAREHOUSE_SKILL_GROUPS,
    filters.WAREHOUSE_STATUS,
    filters.WAREHOUSE_FLAT_ITEMS_RECENT_UPDATES
  ],
  [tableKeys.WAREHOUSE_CERTIFICATIONS]: [
    {
      ...filters.SPLIT_TEXT_NAME,
      fields: ['texts.original_name']
    },
    filters.WAREHOUSE_CERTIFICATION_GROUPS,
    filters.WAREHOUSE_STATUS,
    filters.WAREHOUSE_FLAT_ITEMS_RECENT_UPDATES
  ],
  [tableKeys.WAREHOUSE_EDUCATION]: [
    {
      ...filters.SPLIT_TEXT_NAME,
      fields: ['texts.original_name']
    },
    filters.WAREHOUSE_EDUCATION_GROUPS,
    filters.WAREHOUSE_STATUS,
    filters.WAREHOUSE_FLAT_ITEMS_RECENT_UPDATES
  ],
  [tableKeys.WAREHOUSE_JOBS]: [
    {
      ...filters.SPLIT_TEXT_NAME,
      fields: ['texts.original_name']
    },
    filters.WAREHOUSE_JOB_GROUPS,
    filters.WAREHOUSE_STATUS,
    filters.WAREHOUSE_DOCUMENTS_RECENT_UPDATES
  ],
  [tableKeys.WAREHOUSE_INTERVIEW_QUESTIONS]: [
    {
      ...filters.SPLIT_TEXT_NAME,
      fields: ['texts.original_name']
    },
    filters.WAREHOUSE_COMPETENCIES_BY_GROUPS,
    filters.WAREHOUSE_STATUS,
    filters.WAREHOUSE_FLAT_ITEMS_RECENT_UPDATES
  ],
  // Interviews
  [tableKeys.COMPETENCY_QUESTIONS]: [
    filters.SPLIT_TEXT_NAME,
    filters.COMPETENCIES_BY_GROUPS
  ],
  [tableKeys.CANDIDATES]: [
    {
      ...filters.NAME,
      fields: [
        'firstname',
        'lastname',
        'email'
      ],
      placeholder: t('search_name_or_email')
    },
    {
      ...filters.DATE_RANGE,
      localeKey: ['created_date'],
      field: 'created_at'
    },
    {
      key: 'CandidatesLatestStatus',
      component: 'FilterCheckboxes',
      field: 'latest_status_ids',
      name: t('latest_status'),
      getOptions: async () => {
        const options = await getCandidatesLatestStatusOptions();

        return options;
      }
    }

  ],
  [tableKeys.OPENINGS]: [
    {
      ...filters.CUSTOM,
      display: false
    },
    filters.SPLIT_TEXT_NAME,
    {
      ...filters.DEPARTMENTS,
      field: 'department_ids'
    },
    {
      ...filters.STATUSES,
      getOptions: () => [
        allOptions.value.ACTIVE,
        {
          ...allOptions.value.CLOSED,
          statusColor: 'grey'
        }
      ].map(x => ({ ...x, status: x?.value }))
    },
    {
      key: 'LOCATIONS',
      component: 'FilterCheckboxes',
      field: 'location_ids',
      name: t('locations', 2),
      hasSearch: true,
      getOptions: async () => {
        const options = await getLocationsOptions();

        return formatOptions(options, t('no_locations'));
      }
    }
  ],
  [tableKeys.GUIDES]: [
    filters.SPLIT_TEXT_NAME,
    filters.GUIDE_GROUPS,
    {
      ...filters.CONTENT_STATUS,
      permissions: [permissions.MANAGE_INTERVIEWS]
    }
  ],
  [tableKeys.TOPIC_QUESTIONS]: [
    filters.SPLIT_TEXT_NAME,
    filters.TOPICS
  ],
  [tableKeys.TASKS]: [
    {
      ...filters.NAME,
      fields: ['title']
    },
    {
      key: 'TaskStarred',
      component: 'FilterCheckboxes',
      field: 'starred',
      getOptions: () => [
        {
          text: t('starred'),
          value: allOptions.value.YES.value,
          icon: 'star',
          color: 'text-yellow-1',
          class: 'icon-filled -mt-px !mr-1.25'
        }
      ]
    },
    filters.TASK_STATUS,
    filters.TASK_TYPES
  ]
};
